import moment from "moment";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { Button, LotiFiles, TableLoader } from "../..";
import { AlertType, alertService } from "../../../utils/alert.service";

function AssignCrew({
  isLoading,
  AssignCrewFormSubmitHandler,
  toggleAssignCrew,
  timeCrew,
  handleSelectedTimeSlots,
  bookSlot,
  order_id,
  item,
}: any) {
  const generateTimeSlots = () => {
    const timeSlot = [];
    const firstSlot = moment(item?.first_slot, "HH:mm");
    const lastSlot = moment(item?.last_slot, "HH:mm");
    const intervalString = item?.timing_of_each_slot;
    const [hours, minutes] = intervalString.split(":").map(Number);
    const interval = moment.duration(hours, "hours").add(minutes, "minutes");
    let currentTime = moment(firstSlot);

    while (currentTime.isSameOrBefore(lastSlot)) {
      const formattedTime = currentTime.format("HH:mm");
      timeSlot.push(formattedTime);
      currentTime.add(interval);
    }

    return timeSlot;
  };

  const generateHeaderData = (timeSlot: string[]) => {
    return [
      "No",
      "Crew Name",
      "Phone Number",
      "Address",
      "Preferred Territory",
      ...timeSlot,
    ];
  };

  const generateListData = () => {
    const listData: any = [];

    timeCrew?.forEach((item: any, index: number) => {
      const crewDetails = [
        {
          title: "No.",
          data: index + 1,
        },
        {
          id: 1,
          title: "Crew Name",
          data: item?.crew_name,
        },
        {
          id: 2,
          title: "Phone Number",
          data: item.phone_number,
        },
        {
          id: 3,
          title: "Address",
          data: item?.address,
        },
        {
          id: 4,
          title: "Territory",
          data: item.preferred_work_territory,
        },
      ];

      const currentCrewTimeSlots = timeCrew
        ?.find((crew: any) => crew?.crew_id === item?.crew_id)
        ?.time_slots.map((timeSlot: any) => ({
          time_slot: moment.utc(timeSlot.time_slot).format("HH:mm"),
          crew_schedule_id: timeSlot.crew_schedule_id,
          order_id: timeSlot.order_id,
        }));

      const combinedList = [
        ...crewDetails,
        ...generateTimeSlots().map((time: string) => {
          const isBooked = currentCrewTimeSlots?.some(
            (slot: any) => slot.time_slot === time
          );
          const orderIds = currentCrewTimeSlots
            ?.filter((slot: any) => slot.time_slot === time)
            .map((slot: any) => slot.order_id)
            .join(", ");

          return {
            title: time,
            data: isBooked ? "booked" : "",
            subData: item?.crew_id,
            order_id: orderIds,
          };
        }),
      ];

      listData.push(combinedList);
    });

    return listData;
  };

  const isAnyBooked = () => {
    return generateListData().some((row: any) =>
      row.some(
        (cell: any) => cell.data === "booked" && cell.order_id === order_id
      )
    );
  };

  const renderTable = () => {
    const HeaderData = generateHeaderData(generateTimeSlots());
    const listData = generateListData();

    if (isLoading) {
      return <TableLoader />;
    }

    return (
      <>
        {listData.length > 0 && HeaderData ? (
          <div className="table-main-div">
            <Table>
              <Thead>
                <Tr>
                  {HeaderData.map((header: any, index) => (
                    <Th key={index}>{header}</Th>
                  ))}
                </Tr>
              </Thead>
              {listData.map((rowData: any, rowIndex: number) => (
                <Tbody key={rowIndex}>
                  <Tr>
                    {rowData.map((cellData: any, cellIndex: number) => (
                      <Td
                        key={cellIndex}
                        onClick={() => {
                          isAnyBooked()
                            ? alertService.alert({
                                type: AlertType.Error,
                                message:
                                  "You have already booked this time slot.",
                              })
                            : handleSelectedTimeSlots(cellData);
                        }}
                        style={{
                          backgroundColor:
                            cellData.data === "booked"
                              ? cellData.order_id === order_id
                                ? "#4caf50"
                                : "#fd8f82"
                              : bookSlot?.subData === cellData?.subData &&
                                bookSlot?.title === cellData?.title
                              ? "#fd8f82"
                              : "transparent",
                          color:
                            cellData.data === "booked"
                              ? cellData.order_id === order_id
                                ? "#4caf50"
                                : "#fd8f82"
                              : bookSlot?.subData === cellData?.subData &&
                                bookSlot?.title === cellData?.title
                              ? "#fd8f82"
                              : "black",
                        }}
                      >
                        {cellData.data ? cellData.data : null}
                      </Td>
                    ))}
                  </Tr>
                </Tbody>
              ))}
            </Table>
          </div>
        ) : (
          <LotiFiles message={"No Data Found!"} />
        )}
      </>
    );
  };

  return (
    <div className="popup-box-wrapper">
      <div className="popup-box-container">
        <div className="flex-col-div">
          <span className="popup-box-title">ASSIGN CREW</span>
        </div>
        <div className="underline" />
        <div
          className="details-list-table"
          style={{ overflowY: "auto", maxHeight: "30rem" }}
        >
          {renderTable()}
        </div>
        <div className="underline" />
        <div className="flex-row-cen-cen-div">
          <Button
            isLoading={isLoading}
            className="add-details-submit-btn"
            name="Submit"
            onClick={AssignCrewFormSubmitHandler}
          />
          <Button
            isLoading={false}
            className="add-details-cancel-btn"
            name="Cancel"
            onClick={toggleAssignCrew}
          />
        </div>
      </div>
    </div>
  );
}

export default AssignCrew;
